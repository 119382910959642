import { useI18n } from "vue-i18n"
import setting from "@/config/setting"

export default () => {
    const $t = useI18n().t;

    const columns = {
        "default_col": [{
            key: 'index',
            width: 48,
            align: 'center',
            customRender: ({
                index
            }) => index + 1
        },
        {
            title: $t('user_order.fields.description'),
            dataIndex: 'description'
        },
        {
            title: $t('share.sku'),
            dataIndex: 'sku',
            width: 200
        },
        {
            title: $t('user_order.fields.pieces'),
            dataIndex: 'pieces',
            width: 120
        },
        {
            title: $t('user_order.fields.declared_value'),
            dataIndex: 'charge_name',
            width: 120,
            customRender: ({
                record
            }) => {
                return `${record.declared_value} ${record.declared_value_currency}`
            }
        },
        {
            title: $t('user_order.fields.manufacture_country_code'),
            dataIndex: 'manufacture_country_code',
            width: 120
        },
        {
            title: $t('user_order.fields.hsc_code'),
            dataIndex: 'hsc_code',
            width: 120
        }
        ],
        'hyd.lbl.morelink56.com': [{
            key: 'index',
            width: 48,
            align: 'center',
            customRender: ({
                index
            }) => index + 1
        },
        {
            title: $t('中文'),
            dataIndex: 'CnName'
        },
        {
            title: $t('英文'),
            dataIndex: 'description'
        },
        {
            title: $t('share.sku'),
            dataIndex: 'sku',
            width: 200
        },
        {
            title: $t('user_order.fields.pieces'),
            dataIndex: 'pieces',
            width: 120
        },
        {
            title: $t('user_order.fields.declared_value'),
            dataIndex: 'charge_name',
            width: 120,
            customRender: ({
                record
            }) => {
                return `${record.declared_value} ${record.declared_value_currency}`
            }
        },
        {
            title: $t('user_order.fields.manufacture_country_code'),
            dataIndex: 'manufacture_country_code',
            width: 120
        },
        {
            title: $t('user_order.fields.hsc_code'),
            dataIndex: 'hsc_code',
            width: 120
        }
        ]
    }

    return {
        columns: columns[setting.env] || columns['default_col']
    }
}